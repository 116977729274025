import React from "react";

const list = [1, 2, 3];
class DemoVids extends React.Component {
  renderVids = () => {
    if (this.props.display > 700) {
      return list.map((data) => {
        return (
          <div className="col-4" key={data}>
            <img src="http://via.placeholder.com/300x200" alt="img" />
          </div>
        );
      });
    } else {
      return (
        <div className="ml-auto mr-auto">
          <img src="http://via.placeholder.com/300x200" alt="img" />
        </div>
      );
    }
  };

  render() {
    // console.log(this.props.display)
    return (
      <div className="announcements-box loadAnnouncement" style={{ display: "grid" }}>
        <div className="loader-containerAnnouncement d-none">
          <div className="loader"></div>
        </div>
        <div className="container">
          <p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/qWRTFgrA6N0"
              frameBorder="0"
              allowFullScreen=""
            ></iframe>
          </p>
        </div>
        <div className="container">
          <p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/A2ecJyePEqM"
              frameBorder="0"
              allowFullScreen=""
            ></iframe>
          </p>
        </div>
        <div className="container">
          <p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/3vitjLxhp0Q"
              frameBorder="0"
              allowFullScreen=""
            ></iframe>
          </p>
        </div>
        <div className="container">
          <p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/79mTUniNpFs"
              frameBorder="0"
              allowFullScreen=""
            ></iframe>
          </p>
        </div>
        <div className="container">
          <p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/KBHRlMNRhUY"
              frameBorder="0"
              allowFullScreen=""
            ></iframe>
          </p>
        </div>
        <div className="container">
          <p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/sH7um0qOzjo"
              frameBorder="0"
              allowFullScreen=""
            ></iframe>
          </p>
        </div>
      </div>
    );
  }
}

export default DemoVids;
